import { JobModel } from '../../models/job.model';
import { JobsApi } from '../../apis/job.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { JobCrudStore } from './job-crud.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { jobsTableConfig } from '../../components/report-table/configurations/adx-repr/jobs/jobs.config';

export class JobStore {
	private crud = new JobCrudStore();
	private list = new BaseListStore<JobModel>({
		listFunc: JobsApi.list,
		model: JobModel,
	});

	settingsStore: SettingsStore;
	jobsReportTable = new NewAdminxReportTableStore(jobsTableConfig);

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		if (!this.settingsStore.getPages().get('jobs')) {
			this.settingsStore.getPages().set('jobs', { tableStore: new TableStore() });
		}
		const jobFilterByKey = new FilterStore({
			key: 'jobs',
			currentFilters: undefined,
			filterPrototypesByProp,
		});

		this.settingsStore.getPages().get('jobs')?.tableStore.setFiltersStore('jobs', jobFilterByKey);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}
}
