import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import { IdColumnConfig } from '../../../columns/adminx.columns';
import { BooleanFilterDefinition, NumbersFilterDefinition, TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { Tag, TagTypes } from '@monorepo/base/src/components/tag/tag';
import routes from '../../../../../routes/index.routes';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { FilterType, IFilterDefinition } from '../../../table-filters/filters.types';
import { ReportFilterType } from '../../../stores/data-stores/old-report.types';

export enum JobStatus {
	Initial = 'INITIAL',
	Running = 'RUNNING',
	Success = 'SUCCESS',
	Error = 'ERROR',
	PartialSuccess = 'PARTIAL_SUCCESS',
}

const statusTagTypeMap = {
	[JobStatus.Initial]: TagTypes.Pending,
	[JobStatus.Running]: TagTypes.New,
	[JobStatus.Success]: TagTypes.Success,
	[JobStatus.Error]: TagTypes.Error,
	[JobStatus.PartialSuccess]: TagTypes.Success,
};

class JobStatusFilterDefinition implements IFilterDefinition {
	type = FilterType.Select;
	actions = [
		{
			label: 'equals',
			value: ReportFilterType.EQUALS,
		},
		{
			label: 'not equals',
			value: ReportFilterType.NOT_EQUALS,
		},
	];
	options = Object.entries(JobStatus).map(([label, value]) => ({ label, value }));
}

export const jobsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxJobsReport',
		sort: [
			{
				column: 'id',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new IdColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),

		new DimensionsColumnConfig({
			header: 'Name',
			name: 'name',
			accessor: 'name',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => <TableLink toEdit={routes.jobs.edit(row.getValue('id'))}>{row.getValue('name')}</TableLink>,
		}),

		new DimensionsColumnConfig({
			header: 'Task',
			name: 'task_classes',
			accessor: 'task_classes',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => <OverflowColumn data={row.getValue('task_classes')} />,
		}),

		new DimensionsColumnConfig({
			header: 'Cron Expression',
			name: 'cron_expression',
			accessor: 'cron_expression',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			header: 'Active',
			name: 'is_active',
			accessor: 'is_active',
			availableFilters: new BooleanFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const v = row.getValue('is_active');
				return <Tag type={v ? TagTypes.Success : TagTypes.Paused}>{v ? 'Active' : 'Paused'}</Tag>;
			},
		}),

		new DimensionsColumnConfig({
			header: 'Run On Init',
			name: 'run_on_init',
			accessor: 'run_on_init',
			availableFilters: new BooleanFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => (row.getValue('run_on_init') ? 'Yes' : 'No'),
		}),

		new DimensionsColumnConfig({
			header: 'Max Retries',
			name: 'max_retries',
			accessor: 'max_retries',
			availableFilters: new NumbersFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			header: 'Retry Timeout',
			name: 'retry_timeout',
			accessor: 'retry_timeout',
			availableFilters: new NumbersFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			header: 'Refresh Rate',
			name: 'refresh_rate',
			accessor: 'refresh_rate',
			availableFilters: new NumbersFilterDefinition(),
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'Status',
			name: 'status',
			accessor: 'status',
			availableFilters: new JobStatusFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const v = (row.getValue('status') as string).trim() as JobStatus;

				return <Tag type={statusTagTypeMap[v]}>{v}</Tag>;
			},
		}),

		new DimensionsColumnConfig({
			header: 'Retry Attempts',
			name: 'retry_attempts',
			accessor: 'retry_attempts',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'Error Message',
			name: 'error_message',
			accessor: 'error_message',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'Start Time',
			name: 'start_time',
			accessor: 'start_time',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'End Time',
			name: 'end_time',
			accessor: 'end_time',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'Job Duration',
			name: 'job_duration',
			accessor: 'job_duration',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new DimensionsColumnConfig({
			header: 'Next Run Time',
			name: 'next_run_time',
			accessor: 'next_run_time',
			visibility: ColumnVisibilityOptions.Visible,
		}),
	],
	includeChart: false,
	tableName: 'adxReprJobs',
};
