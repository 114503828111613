import React, { CSSProperties, ForwardedRef, InputHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { FormError } from '../form-error/form-error';
import styles from './input.module.scss';
import { generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { number } from '@monorepo/tools/src/lib/utils/number';
import { Flex } from '../../flex/flex';
import { randomString } from '@monorepo/tools/src/lib/utils/string';

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
	value?: string;
	onValue?: (value: string) => void;
	required?: boolean;
	requiredAsterisk?: boolean;
	placeholder?: string;
	icon?: string | JSX.Element;
	type?: string;
	id?: string;
	error?: string;
	className?: string;
	unstyled?: boolean;
	iconColor?: string;
	iconSize?: string;
	inputClassName?: string;
	animate?: boolean;
	before?: ReactNode;
	after?: ReactNode;
	borderBefore?: boolean;
	borderAfter?: boolean;
	topLabel?: ReactNode;
	bottomLabel?: ReactNode;
	wrapperStyle?: CSSProperties;
	inputWrapperClassName?: string;
	errorClassName?: string;
	inline?: boolean;
	disabled?: boolean;
	onFocusEvent?: (isFocus: boolean) => void;
	debugProps?: IDebugProps;
	isAutoFocus?: boolean;
	wrapperId?: string;
	removeLabel?: boolean;
	isId?: boolean;
}

// TODO - input delimiter
export const Input = React.forwardRef((props: IInput, ref: ForwardedRef<HTMLInputElement>) => {
	const {
		onChange,
		onValue,
		value = '',
		icon,
		placeholder = '',
		required = false,
		requiredAsterisk = true,
		type = 'text',
		id = '',
		error,
		className,
		unstyled = false,
		iconColor,
		iconSize,
		inputClassName,
		animate = true,
		before,
		after,
		borderBefore,
		borderAfter,
		topLabel,
		bottomLabel,
		wrapperStyle,
		min,
		max,
		inputWrapperClassName,
		errorClassName,
		inline,
		disabled = false,
		onFocusEvent,
		debugProps,
		isAutoFocus,
		wrapperId,
		removeLabel,
		isId,
		...rest
	} = props;
	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const [, setIsTouched] = useState<boolean>(false);
	const [isFocus, setIsFocus] = useState<boolean>(false);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsTouched(true);
		if (required && e.target.value) {
			setErrorMsg(undefined);
		}

		if (onChange) {
			onChange(e);
		}
		if (onValue) {
			onValue(e.target.value);
		}
	};

	const _onFocus = () => {
		setIsFocus(true);
		if (onFocusEvent) {
			onFocusEvent(true);
		}
	};

	const _onBlur = () => {
		if (type === 'positiveNumber' || type === 'number') {
			if (min && value && parseFloat(value) < (min as number)) {
				setErrorMsg(`Minimum value is ${min}`);
			}
			if (max && value && parseFloat(value) > (max as number)) {
				setErrorMsg(`Maximum value is ${max}`);
			}
		}
		setIsFocus(false);
		if (onFocusEvent) {
			onFocusEvent(false);
		}
	};

	const renderDisplayedNumber = (value: string) => {
		if (isId) {
			return value;
		}
		if (number(parseFloat(value), true, false) === 'NaN') {
			return '';
		}

		return number(parseFloat(value), true, false);
	};

	const inputClassNames: string[] = [];
	const inputWrapperClassNames = [];
	if (!unstyled) {
		inputClassNames.push(styles.input);
	}
	if (icon) {
		inputClassNames.push(styles.inputWithIcon);
	}
	if (before) {
		inputClassNames.push(styles.inputWithPrefix);
	}
	if (value && animate) {
		inputClassNames.push(styles.notEmpty);
	}
	if (errorMsg) {
		inputClassNames.push(styles.inputError);
	}
	// if (isTouched && value) {
	// 	inputClassNames.push(styles.inputTouched);
	// }
	if (inputClassName) {
		inputClassNames.push(inputClassName);
	}
	if (inline) {
		inputWrapperClassNames.push(styles.inline);
	}
	if (!animate) {
		inputClassNames.push(styles.disableAnimate);
	}

	const _type = type === 'positiveNumber' ? 'number' : type;
	let _min = min;
	if (type === 'positiveNumber' && !min) {
		_min = 0;
	}

	return (
		<div
			id={`${wrapperId || randomString(10)}`}
			className={`${unstyled ? styles.unstyledWrapper : styles.wrapper} ${className ?? ''}`}
			style={wrapperStyle || {}}>
			{topLabel ? <div className={styles.topLabel}>{topLabel}</div> : null}
			<div
				className={`${styles.inputWrapper} ${disabled ? styles.disabled : ''} ${isFocus ? styles.focused : ''} ${
					errorMsg ? styles.inputError : ''
				} ${inputWrapperClassName || ''} ${inputWrapperClassNames.join(' ')}`}>
				{icon ? (
					<Icon isMFP={typeof icon === 'string'} className={styles.icon} color={iconColor} size={iconSize}>
						{icon}
					</Icon>
				) : null}
				{before ? <span className={`${styles.before} ${borderBefore ? styles.borderBefore : ''}`}>{before}</span> : ''}
				<input
					onChange={_onChange}
					onFocus={_onFocus}
					onBlur={_onBlur}
					className={`${inputClassNames.join(' ')} ${
						_type === 'number' && !isFocus && value && value !== 'NaN' ? styles.hide : ''
					}`}
					placeholder={`${placeholder} ${required && requiredAsterisk ? '*' : ''}`}
					type={_type}
					value={value}
					required={required}
					ref={ref}
					min={_min}
					id={id || randomString(10)}
					autoFocus={isAutoFocus}
					disabled={Boolean(disabled)}
					{...generateDataAttrs(suffixToDataAttr('_input', dataAttrs))}
					{...rest}
				/>
				{_type === 'number' && !isFocus && value && value !== 'NaN' && (
					<span
						className={`${styles.displayedNumberValue} ${icon ? styles.removePadding : ''}`}
						onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
							//workaround to focus on the end of the number, thank god for chatgpt
							const input = e.currentTarget?.previousSibling as HTMLInputElement;
							input.type = 'text';
							input.setSelectionRange(input.value.length, input.value.length);
							input.type = 'number';
							input.focus();
						}}>
						{renderDisplayedNumber(value)}
					</span>
				)}
				{after ? (
					<span className={`${styles.after} ${borderAfter ? styles.borderAfter : ''}`}>
						<Flex height={'100%'} alignItems={'center'}>
							{after}
						</Flex>
					</span>
				) : (
					''
				)}
				{unstyled || removeLabel ? null : (
					<label htmlFor={id} className={unstyled ? '' : styles.label}>
						{placeholder} {required && requiredAsterisk ? '*' : ''}
					</label>
				)}
			</div>
			{bottomLabel ? <div className={styles.bottomLabel}>{bottomLabel}</div> : null}
			<FormError msg={errorMsg} className={errorClassName} />
		</div>
	);
});
