import { ElementType, Fragment } from 'react';
import { useTableContext } from '../table-component';
import { TableRawActionButton } from '../table-elements/table-actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import routes from '../../../routes/index.routes';
import { DeleteModal } from '../../modals/delete-modal';
import { observer } from 'mobx-react';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { IButton, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CreativesApi } from '../../../apis/creatives.api';
import { DetachCampaignCreativeModal } from '../../modals/detach-campaign-creative-modal';
import { AttachCreativeCampaigns } from '../../modals/attach-creative-campaigns/attach-creative-campaigns';
import { CreativeModel } from '../../../models/creative.model';
import { SendToReviewModal } from '../../modals/send-to-review-modal';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { AllowMultipleDownloadsModal } from '../../modals/allow-multiple-downloads-model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useParams } from 'react-router-dom';

type Props = {
	hasDetach?: boolean;
};

export const CreativesRowActions = observer(({ hasDetach }: Props) => {
	const { tableStore } = useTableContext();
	const { creativeStore, modalsStore, toastsStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();
	const { campaignId } = useParams();

	const selectedIds = tableStore.getSelectedRows().map(r => r.creative_id) as string[];

	const creatives = selectedIds.map(id =>
		creativeStore
			.getListStore()
			.getList()
			.find(creative => creative.getId() === id)
	);

	const isSameAdvertiser = creatives.every(creative => {
		return creative?.getCreativeGroup()?.getAdvertiser()?.getId() === creatives[0]?.getCreativeGroup()?.getAdvertiser()?.getId();
	});

	const creativeSingleAction = tableStore.getSelectedRows()[0];

	const onDownload = (ids: string[]) => creativeStore.downloadCreativeHtmlFiles(ids);

	const deleteCreatives = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteId = selectedIds[0];
						creativeStore
							.getCrud()
							.delete(deleteId)
							.then(() => {
								dispatchLog({
									msg: `creative: ${creativeSingleAction['creative_brand']}${deleteId} ${'Deleted'}`,
									type: DispatchLogsTypes.Success,
								});
								tableStore.onRowSelectionChange({});
								tableStore.fetchReport({}, false);
							})
							.catch(() => {
								dispatchLog({
									msg: `Delete ${deleteId} Failed`,
									type: DispatchLogsTypes.Error,
								});
							});
					}}>
					<DeleteModal label={`Are you sure you want to delete '${creativeSingleAction['creative_brand']}' creative?`} />
				</ActionModal>
			),
		});
	};

	const sendToReview = (creativeIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Send to review'}
					onAction={() => {
						CreativesApi.sendToReview(creativeIds)
							.then(() => {
								toastsStore.addToastOnNav({ msg: `Sent to review successfully`, type: DispatchLogsTypes.Success });
								navigate(0);
							})
							.catch(() => {
								toastsStore.addToast({ msg: `Failed to send to review`, type: DispatchLogsTypes.Error });
							});
					}}>
					<SendToReviewModal creativeIds={creativeIds} />
				</ActionModal>
			),
		});
	};

	const _onDownload = () => {
		if (!onDownload) {
			return;
		}

		if (selectedIds.length === 1) {
			onDownload(selectedIds);
		}
		if (!modalsStore.getIsAllowMultipleDownloadsModalDisplayed()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Info}
						primaryBtnTitle={'Got it!'}
						onAction={() => {
							onDownload(selectedIds);
							modalsStore.setIsAllowMultipleDownloadsModalDisplayed(true);
						}}>
						<AllowMultipleDownloadsModal />
					</ActionModal>
				),
			});
		} else {
			onDownload(selectedIds);
		}
	};

	const attachCampaigns = (creativeIds: id[]) => {
		const creatives = creativeIds.map(id =>
			creativeStore
				.getListStore()
				.getList()
				.find(creative => creative.getId() === id)
		);

		modalsStore.addModal({
			children: (
				<ActionModal
					primaryBtnTitle={'Attach Campaigns'}
					onAction={() => {
						const campaignIds =
							creativeStore
								.getCrud()
								.getData()
								.getCampaigns()
								?.map(campaign => campaign.getId()) || [];
						const promises = creativeIds.map(creativeId => {
							return CreativesApi.attachCampaigns(creativeId, campaignIds);
						});
						Promise.allSettled(promises).then(results => {
							const successIds: id[] = [];
							const failedIds: id[] = [];
							results.forEach((result, index) => {
								if (result.status === 'fulfilled') {
									successIds.push(creativeIds[index]);
								} else {
									failedIds.push(creativeIds[index]);
								}
							});
							if (successIds.length > 0) {
								dispatchLog({
									msg: `${successIds.length > 1 ? `Creatives` : `Creative`} ${successIds} Attached`,
									type: DispatchLogsTypes.Success,
								});
							}
							if (failedIds.length > 0) {
								dispatchLog({
									msg: `${failedIds.length > 1 ? `Creatives` : `Creative`} ${failedIds} Failed to attach`,
									type: DispatchLogsTypes.Error,
								});
							}
						});
					}}>
					<AttachCreativeCampaigns creatives={creatives as CreativeModel[]} />
				</ActionModal>
			),
		});
	};

	const onDetach = (creativeIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Detach'}
					onAction={() => {
						if (!campaignId) {
							return null;
						}
						CreativesApi.detachCampaignCreatives(creativeIds, campaignId).then(() => {
							toastsStore.addToastOnNav({
								msg: `${creativeIds.length > 1 ? `Creatives` : `Creative`} Detached`,
								type: DispatchLogsTypes.Success,
							});
							navigate(0);
						});
					}}>
					<DetachCampaignCreativeModal creativeIds={creativeIds} />
				</ActionModal>
			),
		});
	};

	const DownloadAction = (props: IButton<HTMLButtonElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		if (!onDownload) {
			return null;
		}
		return (
			<SecondaryText
				icon={'chevron-down-double'}
				onClick={_onDownload}
				textColor={textColor}
				iconColor={iconColor}
				debugProps={{
					dataAttrs: suffixToDataAttr('_selected_download_creative', [new DataAttribute('id', 'creatives_table_create')]),
				}}
				{...props}>
				Download
			</SecondaryText>
		);
	};

	const DetachAction: ElementType = (props: IButton<HTMLButtonElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		return (
			<SecondaryText icon={'link-off'} onClick={() => onDetach(selectedIds)} textColor={textColor} iconColor={iconColor} {...props}>
				Detach
			</SecondaryText>
		);
	};

	const DebugAction: ElementType = (props: IButton<HTMLButtonElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		return (
			<SecondaryText
				disabled={!selectedIds || selectedIds.length > 1}
				icon={'code-02'}
				onClick={() => navigate(routes.creatives.debug(selectedIds[0]))}
				textColor={textColor}
				iconColor={iconColor}
				{...props}>
				Debug
			</SecondaryText>
		);
	};

	return (
		<Fragment>
			<SelectedLinerButton>
				<SecondaryText
					disabled={!isSameAdvertiser}
					iconSize={'22px'}
					icon={'link-02'}
					onClick={() => attachCampaigns(selectedIds)}
					textColor={'white'}>
					Attach
				</SecondaryText>
			</SelectedLinerButton>
			<SelectedLinerButton>
				<SecondaryText icon={'eye'} onClick={() => sendToReview(selectedIds)} textColor={'white'}>
					Review
				</SecondaryText>
			</SelectedLinerButton>

			<TableRawActionButton
				text="Edit"
				to={routes.creatives.edit(selectedIds[0])}
				icon="edit-02"
				disabled={selectedIds.length !== 1}
			/>

			<TableRawActionButton text="Delete" onClick={deleteCreatives} icon="trash-02" disabled={selectedIds.length !== 1} />

			<SelectedLinerButton>
				<Tooltip content={`Preview and debug the creative content`}>
					<DebugAction />
				</Tooltip>
			</SelectedLinerButton>
			<SelectedLinerButton>
				<Tooltip content={`Download creative html file`}>
					<DownloadAction />
				</Tooltip>
			</SelectedLinerButton>

			{!!hasDetach && (
				<SelectedLinerButton>
					<Tooltip content={`Detach from campaign`}>
						<DetachAction />
					</Tooltip>
				</SelectedLinerButton>
			)}
		</Fragment>
	);
});
