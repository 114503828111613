import { URLSearchParams } from 'url';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { IBidderInputOptionsResponse } from '../models/bidder-input-options.model';

export const BidderInputOptionsApi = {
	get: (path: string, options?: URLSearchParams): Promise<IBidderInputOptionsResponse> => {
		return ask.get(endpoints.bidder.inputOptions(path), options);
	},
};
