/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { runInAction } from 'mobx';
import { BidderCampaignModel } from '../../models/bidder-campaign/bidder-camapign.model';
import { HttpError } from '../../models/http-error.model';
import { BidderApi } from '../../apis/bidder.api';
import { BidderCampaignResponseModel } from '../../models/bidder-campaign/bidder-campaign-response.model';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';

export class BidderCampaignCrudStore extends BaseCrudStore<
	BidderCampaignResponseModel,
	BidderCampaignModel | undefined,
	BidderCampaignModel | undefined,
	HttpError
> {
	constructor() {
		super({
			// @ts-ignore
			apiLayer: BidderApi,
			model: BidderCampaignResponseModel,
			errorModel: HttpError,
		});
	}

	public isValid(): boolean {
		this.formStore.reset();

		const { campaign_weight } = this.data.campaign;

		if (campaign_weight) {
			if (campaign_weight < 0) {
				this.formStore.addError(new FormError('campaign_weight', 'Minimum value is 0'));
			}
			if (campaign_weight > 100) {
				this.formStore.addError(new FormError('campaign_weight', 'Maximun value is 100'));
			}
		}

		return this.formStore.getIsValid();
	}

	getCreateFormData() {
		return this.getData().campaign;
	}

	getEditFormData() {
		return this.getData().campaign;
	}

	createInstance(): BidderCampaignResponseModel {
		return new BidderCampaignResponseModel();
	}

	public getBidderCampaign(id: id) {
		this.setIsLocalCache(true);
		this.setIsLoading(true);

		return BidderApi.getBidderCampaign(id)
			.then(res => {
				const data = new this.model(res);
				const originData = new this.model(res);
				runInAction(() => {
					this.setIsLocalCache(false); //TODO: move to finally
					this.setData(data);
					this.setOriginData(originData);
					this.setIsLoading(false);
				});
				return data;
			})
			.catch(error => {
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response?.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	enableCampaign(campaignsId: id): Promise<void> {
		this.setIsLoading(true);
		return BidderApi.enableCampaign(campaignsId)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseCampaign(campaignsId: id): Promise<void> {
		this.setIsLoading(true);
		return BidderApi.pauseCampaign(campaignsId)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}
}
