import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { FormError } from '../form/form-error/form-error';
import styles from './dropdown-new.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { SelectNew, SelectOptions } from '../select-new/select-new';
import { Icon } from '../icon/icon';

export type option = string;

interface IDropdownNew {
	label?: string;
	options: SelectOptions[];
	defaultValues?: (string | number)[];
	onSelect?: (value: (string | number)[]) => void;
	disabled?: boolean;
	multi?: boolean;
	required?: boolean;
	error?: string;
	autocomplete?: boolean;
	autocompletePlaceholder?: string;
	onAutocompleteKeydown?: (e: React.KeyboardEvent<HTMLElement>) => void;
	tooltip?: boolean;
	className?: string;
	limitTags?: number;
	showAllTag?: boolean;
	showClearAllButton?: boolean;
	id?: string;
	debugProps?: IDebugProps;
	isKeyboardNavigation?: boolean;
	classNames?: {
		dropdownWrapper?: string;
		parentSelectWrapper?: string;
		selectWrapper?: string;
		dropdownInput?: string;
		listItem?: string;
		list?: string;
		autocompleteInput?: string;
		autocompleteInputWrapper?: string;
	};
	onBlur?: (option: Set<option> | undefined) => void;
	isLabelOnBorder?: boolean; // TODO - change this ugly shit when making publifesto as the default css
}

/**
 * @param props
 * @returns
 */
export const DropdownNew = (props: IDropdownNew) => {
	const {
		debugProps,
		error,
		id,
		disabled,
		className,
		label,
		required,
		onSelect,
		options,
		multi,
		defaultValues,
		isLabelOnBorder,
		classNames,
	} = props;

	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const [isOpenDropdown, setDropdown] = useState<boolean>(false);
	const { dataAttrs } = debugProps || {};
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dropdownOptionsRef = useRef<HTMLDivElement>(null);
	const [positionClass, setPositionClass] = useState<string>(styles.fromBottom);

	useEffect(() => {
		if (dropdownOptionsRef.current) {
			const dropdownOptionsRefRect = dropdownOptionsRef.current.getBoundingClientRect();
			if (dropdownOptionsRefRect.bottom + 20 >= window.innerHeight) {
				// we are in the bottom
				setPositionClass(styles.fromTop);
			}
		}
	}, [isOpenDropdown]);

	useOnClickOutside(dropdownRef, () => {
		setDropdown(false);
	});

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const renderDefaultValues = () => {
		if (defaultValues) {
			return defaultValues.map(value => {
				const option = options.find(option => {
					return option.value === value;
				});
				if (option) {
					return (
						<div
							key={value}
							className={`${styles.defaultValue} ${multi ? styles.multi : ''} ${disabled ? styles.disabled : ''}`}>
							{option.label}&nbsp;
							{!disabled && !required && (
								<Icon
									className={`${styles.closeIcon}`}
									isMFP={true}
									size={'15px'}
									color={'#98A2B3'}
									onClick={e => {
										e.stopPropagation();
										setDropdown(false);
										onSelect && onSelect(defaultValues.filter(value => value !== option.value));
									}}>
									x-close
								</Icon>
							)}
						</div>
					);
				}
			});
		}
	};

	return (
		<div
			className={`${styles.wrapper} ${classNames?.dropdownWrapper}`}
			id={id}
			ref={dropdownRef}
			{...generateDataAttrs(suffixToDataAttr('_dropdown', dataAttrs))}>
			<div className={`${styles.InputWrapper} ${className}`}>
				{label && (
					<label className={`${styles.label} ${isLabelOnBorder ? styles.labelOnBorder : ''}`}>
						{label} {required ? '*' : ''}
					</label>
				)}
				<div
					className={`${styles.dropdownInput} ${disabled ? styles.disabled : ''} ${classNames?.dropdownInput}  ${
						isOpenDropdown ? styles.focused : ''
					}`}
					onClick={() => {
						setDropdown(() => !isOpenDropdown);
					}}>
					<span className={styles.defaultValues}>{renderDefaultValues()}</span>
					{!disabled && defaultValues && defaultValues.length > 0 && (!required || multi) && (
						<Icon
							className={`${styles.closeIcon}`}
							isMFP={true}
							size={'16px'}
							onClick={e => {
								e.stopPropagation();
								setDropdown(false);
								onSelect && onSelect([]);
							}}>
							x-close
						</Icon>
					)}
					<Icon
						className={`${styles.arrowIcon} ${disabled ? styles.disabled : ''}`}
						isMFP={true}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
						chevron-down
					</Icon>
				</div>
			</div>
			{disabled ? null : isOpenDropdown ? (
				<div className={`${styles.selectListWrapper} ${classNames?.parentSelectWrapper}`} ref={dropdownOptionsRef}>
					<SelectNew
						disabled={disabled}
						classNames={{
							wrapper: `${styles.fromDropdown} ${positionClass} ${classNames?.selectWrapper}`,
							list: `${styles.list} ${classNames?.list}`,
							listItem: classNames?.listItem,
							autocompleteInput: classNames?.autocompleteInput,
							autocompleteInputWrapper: classNames?.autocompleteInputWrapper,
						}}
						onSelect={select => {
							if (!multi) {
								setDropdown(false);
							}
							onSelect && onSelect(select);
						}}
						options={options}
						placeholder={label}
						defaultValues={defaultValues}
						multiple={multi}
					/>
				</div>
			) : null}
			<FormError msg={errorMsg} />
		</div>
	);
};
