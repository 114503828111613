import { observer } from 'mobx-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { TableDefaultButton } from '../../../modules/components/report-table/table-elements/table-buttons';
import routes from '../../../modules/routes/index.routes';
import ReportTable from '../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';

export const Jobs: FC = observer(() => {
	const {
		jobStore: { jobsReportTable },
	} = useStores<IAdminxStore>();

	useTableStore({ reportTableStore: jobsReportTable });

	return (
		<Page>
			<Pagebar debugProps={{ dataAttrs: [new DataAttribute('id', 'jobs_pagebar')] }}>
				<PagebarTitle>Jobs</PagebarTitle>
			</Pagebar>

			<ReportTable tableStore={jobsReportTable}>
				<TableDefaultButton to={routes.jobs.create()} />
			</ReportTable>

			<Outlet />
		</Page>
	);
});
