import { generateMinPerformanceColumns } from '../../../columns/metrics.columns';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DataColumnConfig, DimensionsColumnConfig } from '../../../columns/columns';
import routes from '../../../../../routes/index.routes';
import { CreativePreview } from '@monorepo/base/src/components/table/creative-preview/creative-preview';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { HighlighStatus, HighlightStatuses } from '@monorepo/base/src/components/highlight/highlight';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { AdvertiserEntityFilterDefinition } from '../../../table-filters/filters.definitions';

const _mapToTagStatus = (status: string | undefined): HighlighStatus => {
	if (status === 'approved') {
		return HighlightStatuses.Success;
	}
	if (status === 'disapproved') {
		return HighlightStatuses.Error;
	}
	if (status === 'new') {
		return HighlightStatuses.New;
	}
	if (status === 'pending_review') {
		return HighlightStatuses.Pending;
	}
	return HighlightStatuses.Info;
};

export const creativesTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCreativesReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new DataColumnConfig({
			name: 'creative_id',
			accessor: 'creative_id',
			header: 'ID',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
		}),

		new DimensionsColumnConfig({
			header: 'Advertiser',
			accessor: 'advertiser_id',
			name: 'advertiser_id',
			visibility: ColumnVisibilityOptions.Hidden,
			availableFilters: new AdvertiserEntityFilterDefinition(),
		}),

		new DimensionsColumnConfig({
			header: 'Brand',
			accessor: 'creative_brand',
			name: 'creative_brand',
			visibility: ColumnVisibilityOptions.Visible,
			displaySegment: true,
			cell: ({ row }) => (
				<TableLink toEdit={routes.creatives.edit(row.getValue('creative_id'))}>{row.getValue('creative_brand')}</TableLink>
			),
		}),
		new DimensionsColumnConfig({
			header: 'Description',
			accessor: 'description',
			name: 'description',
			visibility: ColumnVisibilityOptions.Hidden,
		}),
		new DimensionsColumnConfig({
			header: 'Resource Preview',
			accessor: 'resource_type_2',
			name: 'resource_type',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => (
				<CreativePreview type={(row.getValue('resource_type') as string)?.trim()} data={row.getValue('resource_url')} />
			),
		}),
		new DataColumnConfig({
			header: 'Resource URL',
			accessor: 'resource_url',
			name: 'resource_url',
			visibility: ColumnVisibilityOptions.Hidden,
			cell: ({ row }) => <TableLink to={row.getValue('resource_url')}>{row.getValue('resource_url')}</TableLink>,
		}),
		new DimensionsColumnConfig({
			header: 'Network Status',
			accessor: 'adx_network_status',
			name: 'adx_network_status',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const creativeNetworkStatus = ((row.original.adx_network_status as string) || '').trim();

				return (
					<Tag withIcon={true} type={_mapToTagStatus(creativeNetworkStatus?.toLowerCase())}>
						{creativeNetworkStatus}
					</Tag>
				);
			},
		}),
		new DimensionsColumnConfig({
			header: 'Deals Status',
			accessor: 'adx_deals_status',
			name: 'adx_deals_status',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const DealsStatus = ((row.original.adx_deals_status as string) || '').trim();

				return (
					<Tag withIcon={true} type={_mapToTagStatus(DealsStatus?.toLowerCase())}>
						{DealsStatus}
					</Tag>
				);
			},
		}),
		new DimensionsColumnConfig({
			header: 'Destination URL',
			accessor: 'default_dest_url',
			name: 'default_dest_url',
			visibility: ColumnVisibilityOptions.Hidden,
			cell: ({ row }) => <OverflowColumn data={row.getValue('default_dest_url')} />,
		}),
		new DimensionsColumnConfig({
			header: 'Creative Group Name',
			accessor: 'creative_group_name',
			name: 'creative_group_name',
			visibility: ColumnVisibilityOptions.AlwaysHidden,
		}),
		new DimensionsColumnConfig({
			header: 'Size',
			accessor: 'format',
			name: 'format',
			visibility: ColumnVisibilityOptions.Hidden,
		}),
		new DimensionsColumnConfig({
			header: 'Resource Type',
			accessor: 'resource_type',
			name: 'resource_type',
			visibility: ColumnVisibilityOptions.Hidden,
		}),
		new DimensionsColumnConfig({
			header: 'Creative Group',
			accessor: 'creative_group_id',
			name: 'creative_group_id',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const creativeGroupId = row.getValue('creative_group_id') as string;

				if (!creativeGroupId) {
					return null;
				}

				return (
					<TableLink to={routes.creativeGroups.creatives(creativeGroupId)}>
						{row.original.creative_group_name as string}
					</TableLink>
				);
			},
		}),
		...generateMinPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprCreativesPerformance-na-na',
};
