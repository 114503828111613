import { CellContext } from '@tanstack/react-table';
import { IFilterDefinition } from '../table-filters/filters.types';
import { IDataType } from '../stores/data-stores/data-store.types';

export type ColumnType = 'dimensions' | 'metrics' | 'data' | 'actions';

export enum ColumnVisibilityOptions {
	AlwaysVisible = 'alwaysVisible',
	AlwaysHidden = 'alwaysHidden',
	Visible = 'visible',
	Hidden = 'hidden',
}

export interface IColumnConfig {
	name: string; // The column name in the report fetching
	accessor: string; // The accessor for the column in the table
	header: string; // The header title for the table
	availableFilters?: IFilterDefinition; // Available filters for the column
	type: ColumnType; // Type of the column (dimensions, metrics, or data)
	visibility: ColumnVisibilityOptions; // Visibility attribute
	inSpeedyMode?: boolean; // Speedy mode attribute
	cell?: (info: CellContext<IDataType, unknown>) => JSX.Element | null | string; // Cell renderer for the column
	linkTo?: string[];
	displaySegment?: boolean;
	aggregateWhenSegment?: boolean;
	footerFormatter?: (value: unknown) => string;
	graphFormatter?: (value: unknown) => string;
	tooltip?: string;
}
