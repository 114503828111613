import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { SelectionList } from '@monorepo/base/src/components/selection-list/selection-list';
import { Card } from '@monorepo/base/src/components/card/card';
import { DealTypes } from '../../../../enums/deal-types';
import { useList } from '../../../../hooks/tools/use-list';
import { DealModel } from '../../../../models/deal.model';
import { StrategyModel } from '../../../../models/strategy.model';
import { IAdminxStore } from '../../../../stores';
import { SelectionListOption } from '../../../selection-list-option/selection-list-option';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Input } from '@monorepo/base/src/components/form/input/input';

const defaultStrategryForPreferredDealType = 'PreferredDeals';

interface DealOption {
	name: string;
	description: string;
}

export const Pretargeting: FC = observer(() => {
	const { campaignStore, dealStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const inputOptions = campaignStore.getInputOptions();
	const strategies = inputOptions.getStrategies();
	const pretargetings = inputOptions.getPretargetings();
	const campaign = campaignStore.getCrud().getData();
	const dealType = campaign.getDealType();
	const billingId = campaign.getBillingId();
	const billingName = pretargetings?.find(_pretargeting => _pretargeting.getBillingId() === billingId)?.getName();
	const strategy = campaign.getStrategy();
	const deals = dealStore.getListStore().getList();

	useList<DealModel>(dealStore.getListStore());

	const dealOptions = useMemo(
		() =>
			deals.map(deal => {
				return {
					name: `${deal.getDealId()}` || '',
					description: `${deal.getDealName()}` || '',
				};
			}),
		[deals]
	);

	const onDealType = (setValues: Set<string>) => {
		const _pretargetingType = setValues.values().next().value;

		if (_pretargetingType === DealTypes.PreferredDeal) {
			// reset dropdowns
			const strategy = strategies.find(_strategy => _strategy.name === defaultStrategryForPreferredDealType);
			if (strategy) {
				campaign.setStrategy(new StrategyModel(strategy));
			}
			formStore.clearError('billing_id');
			campaign.setBillingId(undefined);
		}

		if (_pretargetingType === DealTypes.OpenAuction) {
			campaign.setBillingId(undefined);
			campaign.setStrategy(undefined);
			campaign.setPreferredDeals([]);
		}
		campaign.setDealType(_pretargetingType);
	};

	const onBillingIdSelect = (pretargetingName: option) => {
		const _pretargeting = pretargetings.find(_pretargeting => _pretargeting.name === pretargetingName);
		if (_pretargeting) {
			campaign.setBillingId(_pretargeting.getBillingId());
			formStore.clearError('billing_id');
		}
	};

	const onStrategySelect = (strategyName: option) => {
		const _strategy = strategies.find(_strategy => _strategy.name === strategyName?.split('(')?.[0]?.trim());
		if (_strategy) {
			campaign.setStrategy(new StrategyModel(_strategy));
		}
	};

	const onDealSelect = (options: DealOption[]) => {
		const _selectedDeals = options.map(option =>
			deals.find(deal => {
				return option.name === `${deal.getDealId()}`;
			})
		);
		const dealsArr: DealModel[] = [];
		_selectedDeals.forEach(_deal => {
			if (_deal) {
				dealsArr.push(new DealModel(_deal));
			}
		});
		campaign.setPreferredDeals(dealsArr);
	};

	const selectedDeals = campaign.getPreferredDeals()?.map(deal => {
		return {
			name: `${deal.getDealId()}` || '',
			description: `${deal.getDealName()}` || '',
		};
	});

	const [strategyName, setStartegyName] = useState<string>('');
	useEffect(() => {
		if (strategy?.name && strategy?.getId() && strategyName !== `${strategy?.name} (${strategy?.getId()})`) {
			setStartegyName(`${strategy?.name} (${strategy?.getId()})`);
		}
	});

	return (
		<Card
			id={'pretargeting'}
			title={'Pretargeting'}
			icon={'package-search'}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'pretargeting_section')] }}>
			<Spacer y={2} />
			<ButtonGroup title={'Type'} defaultValue={dealType} onChange={onDealType} required={true}>
				<GButton
					icon={'gavel'}
					text={DealTypes.OpenAuction}
					value={DealTypes.OpenAuction}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'pretargeting_type_open_auction')] }}
				/>
				<GButton
					icon={'handshake'}
					text={DealTypes.PreferredDeal}
					value={DealTypes.PreferredDeal}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'pretargeting_type_preferred_deal')] }}
				/>
			</ButtonGroup>
			{dealType === DealTypes.OpenAuction && (
				<Dropdown
					required={dealType === DealTypes.OpenAuction || !dealType}
					defaultOption={billingName}
					label={'Select Billing Id'}
					options={pretargetings.map(_pretargeting => _pretargeting.name)}
					onSelect={(pretargetingName: Set<option> | undefined) => onBillingIdSelect(pretargetingName?.values().next().value)}
					error={formStore.getErrors().get('billing_id')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'billing_id')] }}
				/>
			)}
			<Dropdown
				required={true}
				defaultOption={dealType === DealTypes.PreferredDeal ? defaultStrategryForPreferredDealType : strategyName}
				label={'Select Strategy'}
				disabled={dealType === DealTypes.PreferredDeal}
				options={strategies.map(_strategy => `${_strategy.name} (${_strategy.getId()})`)}
				onSelect={(strategyName: Set<option> | undefined) => onStrategySelect(strategyName?.values().next().value)}
				autocomplete={true}
				error={formStore.getErrors().get('strategy')?.getMsg()}
				tooltip={true}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'strategy')] }}
			/>
			{dealType === DealTypes.PreferredDeal && (
				<Fragment>
					<Spacer y={2} />
					<SelectionList
						label={'Deals'}
						options={dealOptions}
						defaultOptions={selectedDeals}
						onSelect={(deal: DealOption[]) => onDealSelect(deal)}
						isLoadingOptions={campaignStore.getListStore().getIsLoading()}
						isSelectionList={true}
						renderOption={option => (
							<SelectionListOption option={option} debugProps={{ dataAttrs: [new DataAttribute('id', 'deals')] }} />
						)}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'deals_dropdown')] }}
					/>
				</Fragment>
			)}

			<Input
				value={campaign.getProductId()}
				placeholder="Product ID"
				onValue={value => campaign.setProductId(value)}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'yid')] }}
			/>

			<Textarea
				required={true}
				value={campaign.getDestinationUrl()}
				placeholder={'Destination URL'}
				onValue={value => {
					campaign.setDestinationUrl(value);
					// if (!isValidUrl(value)) {
					// 	formStore.addError(new FormError('dest_url', 'Please provide a valid url'));
					// } else {
					// 	formStore.clearError('dest_url');
					// }
				}}
				error={formStore.getErrors().get('dest_url')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'dest_url')] }}
			/>
		</Card>
	);
});
