import { makeAutoObservable, runInAction } from 'mobx';
import { URLSearchParams } from 'url';
import { BidderInputOptionsApi } from '../../apis/bidder-input-options.api';
import { IBidderInputOptionsGroup } from '../../models/bidder-input-options.model';

export class BidderInputOptionsStore {
	private browser_groups: IBidderInputOptionsGroup[] = [];
	private os_groups: IBidderInputOptionsGroup[] = [];
	private device_types: IBidderInputOptionsGroup[] = [];
	private geos: IBidderInputOptionsGroup[] = [];

	private isLocalCache = false;
	private isLoading = false;
	private error: Error | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	fetch(options?: URLSearchParams): Promise<void> {
		if (this.isLocalCache) {
			return Promise.resolve();
		}

		const inputOptions = [
			{ key: 'device_types', url: 'getDeviceTypes' },
			{ key: 'geos', url: 'getGeos' },
			{ key: 'os_groups', subKey: 'operating_systems', url: 'getOperatingSystems' },
			{ key: 'browser_groups', subKey: 'browsers', url: 'getBrowsers' },
		];

		this.isLocalCache = true;
		this.setIsLoading(true);

		return Promise.all(inputOptions.map(i => BidderInputOptionsApi.get(i.url, options)))
			.then(res => {
				runInAction(() => {
					this.isLocalCache = false;
					inputOptions.forEach((i, index) => {
						// eslint-disable-next-line
						// @ts-ignore
						this[i.key] = i.subKey ? res[index][i.key].flatMap(j => j[i.subKey]) : res[index][i.key];
					});
					this.setIsLoading(false);
				});
			})
			.catch((error: Error) => {
				runInAction(() => {
					this.error = error;
				});
			});
	}

	getIsLoading(): boolean {
		return this.isLoading;
	}

	setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	getError(): Error | null {
		return this.error;
	}

	getDeviceTypes(): IBidderInputOptionsGroup[] {
		return this.device_types;
	}

	getBrowsers(): IBidderInputOptionsGroup[] {
		return this.browser_groups;
	}

	getOperatingSystems(): IBidderInputOptionsGroup[] {
		return this.os_groups;
	}

	getGeos(): IBidderInputOptionsGroup[] {
		return this.geos;
	}
}
