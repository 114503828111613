import { CellContext } from '@tanstack/react-table';
import { NumbersFilterDefinition, PercentageFilterDefinition } from '../table-filters/filters.definitions';
import { IFilterDefinition } from '../table-filters/filters.types';
import { ColumnType, ColumnVisibilityOptions, IColumnConfig } from './columns.types';
import { IDataType } from '../stores/data-stores/data-store.types';
import {
	currencyCellCreator,
	currencyValueFormatter,
	defaultGraphValueFormatter,
	defaultMetricCellCreator,
	defaultMetricValueFormatter,
	percentageCellCreator,
	percentageRatioCellCreator,
	percentageValueFormatter,
} from '../cells/metrics.cells';

type ColumnRequiredConfig = Required<Pick<IColumnConfig, 'name' | 'accessor' | 'header'>> & Partial<IColumnConfig>;

export enum TableType {
	Campaign = 'campaign',
	Advertiser = 'advertiser',
}

export class ColumnConfig implements IColumnConfig {
	name: string;
	accessor: string;
	header: string;
	availableFilters?: IFilterDefinition;
	type: ColumnType;
	visibility: ColumnVisibilityOptions;
	cell?: (info: CellContext<IDataType, unknown>) => JSX.Element | string | null;
	linkTo?: string[];
	displaySegment?: boolean;
	aggregateWhenSegment?: boolean;
	inSpeedyMode?: boolean | undefined;
	footerFormatter: (value: unknown) => string;
	graphFormatter: (value: unknown) => string;
	tableType?: TableType;
	tooltip?: string;

	constructor(column: ColumnRequiredConfig) {
		this.name = column.name;
		this.accessor = column.accessor;
		this.header = column.header;
		this.availableFilters = column.availableFilters;
		this.type = column.type ?? 'data';
		this.visibility = column.visibility ?? ColumnVisibilityOptions.Hidden;
		this.displaySegment = column.displaySegment;
		this.aggregateWhenSegment = column.aggregateWhenSegment;
		this.cell = props =>
			props?.row?.depth === 0
				? column.cell?.(props) || (props?.row?.original?.[column.accessor] as string)
				: (props?.row?.original?.[column.accessor] as string) || null;

		this.linkTo = column.linkTo;
		this.inSpeedyMode = column.inSpeedyMode;
		this.tooltip = column.tooltip;
		this.footerFormatter = column.footerFormatter || defaultMetricValueFormatter;
		this.graphFormatter = column.graphFormatter || defaultMetricValueFormatter;
	}

	isVisible() {
		return this.visibility === ColumnVisibilityOptions.Visible || this.visibility === ColumnVisibilityOptions.AlwaysVisible;
	}
	isHidden() {
		return this.visibility === ColumnVisibilityOptions.Hidden || this.visibility === ColumnVisibilityOptions.AlwaysHidden;
	}
	isAlwaysVisible() {
		return this.visibility === ColumnVisibilityOptions.AlwaysVisible;
	}
	isAlwaysHidden() {
		return this.visibility === ColumnVisibilityOptions.AlwaysHidden;
	}

	isAlwaysHiddenOrVisible() {
		return this.isAlwaysHidden() || this.isAlwaysVisible();
	}
}

export class MetricsColumnConfig extends ColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({
			type: 'metrics',
			availableFilters: new NumbersFilterDefinition(),
			...column,
		});
		this.cell = column.cell ?? defaultMetricCellCreator(column.accessor);
		this.graphFormatter = column.graphFormatter ?? defaultGraphValueFormatter;
	}
}

export class MetricsCurrencyColumnConfig extends MetricsColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({ ...column });
		this.cell = column.cell ?? currencyCellCreator(column.accessor);
		this.footerFormatter = column.footerFormatter ?? currencyValueFormatter;
	}
}

export class MetricsPercentageRatioColumnConfig extends MetricsColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({
			availableFilters: new PercentageFilterDefinition(),
			graphFormatter: percentageValueFormatter,
			...column,
		});

		this.cell = column.cell ?? percentageRatioCellCreator(column.accessor);
		this.footerFormatter = column.footerFormatter ?? percentageValueFormatter;
	}
}

export class MetricsPercentageColumnConfig extends MetricsColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({ ...column });
		this.cell = column.cell ?? percentageCellCreator(column.accessor);
		this.footerFormatter = column.footerFormatter ?? percentageValueFormatter;
	}
}

export class DimensionsColumnConfig extends ColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({
			type: 'dimensions' as ColumnType,
			...column,
		});
	}
}

export class DataColumnConfig extends ColumnConfig {
	constructor(column: ColumnRequiredConfig) {
		super({
			type: 'data' as ColumnType,
			...column,
		});
	}
}

export class ActionsColumnConfig extends ColumnConfig {
	constructor(column: Partial<ColumnRequiredConfig>) {
		super({
			type: 'actions' as ColumnType,
			name: 'actions',
			header: '',
			accessor: 'actions',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
			...column,
		});
	}
}
