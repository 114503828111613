import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IAdminxStore } from '../../stores';

/**
 * @param options
 * @returns
 */
export const useBidderInputOptions = (options?: IRequestOptions) => {
	const { bidderStore } = useStores<IAdminxStore>();
	const location = useLocation();

	useEffect(() => {
		const params = queryParamsToSearchParams(options?.queryParams);

		bidderStore.getInputOptions().fetch(params);
	}, [location]);

	return { error: bidderStore.getInputOptions().getError(), isLoading: bidderStore.getInputOptions().getIsLoading() };
};
