import { observer } from 'mobx-react';
import { BasicInfo } from './basic-info/basic-info';
import styles from './bidder-campaign-form.module.scss';
import { BidderDomainList } from './bidder-domain-limit/bidder-domain-list';
import { BidderTargeting } from './targeting/bidder-targeting';
import { useBidderInputOptions } from '../../../hooks/apis/use-bidder-input-options';

export const BidderCampaignForm = observer(() => {
	useBidderInputOptions();

	return (
		<div className={styles.wrapper}>
			<BasicInfo />
			<BidderTargeting />
			<BidderDomainList />
		</div>
	);
});
