import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IAdminxStore } from '../../../../modules/stores';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { sentenceCase } from 'change-case';
import styles from './edit-bidder.module.scss';
import { ErrorMsg } from '../../../../modules/components/error-msg/error-msg';
import { BidderCampaignForm } from '../../../../modules/components/forms/bidder-campaign/bidder-campaign.form';
import { Statuses } from '../../../../modules/enums/status';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Fragment, useEffect, useState } from 'react';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useBidderCampaignGet } from '../../../../modules/hooks/apis/use-bidder-campaign-get';
import { useParams } from 'react-router-dom';
import { BidderApi } from '../../../../modules/apis/bidder.api';
import routes from '../../../../modules/routes/index.routes';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useBidderCampaignTableOfContents } from '../../../../modules/hooks/toc/bidder-campaign.toc';
import { Nav, NavGroup, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';

export const EditBidderCampaign = observer(() => {
	const { bidderStore, modalsStore } = useStores<IAdminxStore>();
	const bidderCampaignCrudStore = bidderStore.getCrud();
	const isLoading = bidderCampaignCrudStore.getIsLoading();
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const error = bidderCampaignCrudStore.getHttpError();
	const campaign = bidderCampaignCrudStore.getData().campaign;
	const navigate = useNavigateParams();
	const { dispatchLog } = useLogs();
	const { bidderCampaignId } = useParams();

	const { currentRoute } = useRoute();
	const [activeRoute, setActiveRoute] = useState<string>(currentRoute);
	const { tableOfContents, isTableOfContents, setIsTableOfContents } = useBidderCampaignTableOfContents();

	useBidderCampaignGet({ id: bidderCampaignId });
	useEffect(() => {
		bidderStore.publishersList.fetch(undefined);
	}, []);

	useEffect(() => {
		setIsTableOfContents(true);

		return () => {
			bidderCampaignCrudStore.reset();
		};
	}, []);

	const onEditCampaign = () => {
		if (!bidderCampaignCrudStore.isValid()) {
			dispatchLog({
				msg: 'Form is invalid',
				type: DispatchLogsTypes.Error,
			});

			return;
		}

		setIsSaving(true);

		BidderApi.updateCampaign(bidderCampaignCrudStore.getEditFormData().toObject())
			.then(() => {
				navigate(routes.bidder.campaigns());
				dispatchLog({
					msg: `Campaign ${campaign?.campaign_name} was successfully edited`,
					type: DispatchLogsTypes.Success,
				});
			})
			.catch((e: { data: { success: boolean; errors: { message: string; field: string }[] } }) => {
				modalsStore.addModal({
					children: (
						<ActionModal type={ModalTypes.Error} primaryBtnTitle={'OK'}>
							<div className={styles.errorWrapper}>
								<h2 className={styles.title}>{`Failed to edit campaign.`}</h2>
								{e.data.errors.map(error => (
									<div className={styles.errorContent}>{`${error.field}: ${error.message}`}</div>
								))}
							</div>
						</ActionModal>
					),
				});
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={`${routes.bidder.campaigns()}`}>Campaigns</NavItem>
				<Fragment>
					{isTableOfContents && (
						<NavGroup title={'Table of contents'}>
							<TableOfContents heads={tableOfContents} />
						</NavGroup>
					)}
				</Fragment>
			</Nav>
			<Page unstyled={true}>
				<Disable is={isLoading || isSaving}>
					<Page>
						<Pagebar>
							<PagebarTitle unstyled={true}>
								<div className={styles.title}>Edit a campaign</div>
								<SpacerX />
								<Toggle
									label={
										campaign.campaign_status === Statuses.Active
											? sentenceCase(Statuses.Active)
											: sentenceCase(Statuses.Paused)
									}
									onChange={status => {
										campaign.campaign_status = status ? Statuses.Active : Statuses.Paused;
									}}
									defaultValue={campaign.campaign_status === Statuses.Active}
								/>
							</PagebarTitle>
						</Pagebar>
						<BidderCampaignForm />
					</Page>
					<BottomBar>
						{error ? <ErrorMsg error={error} /> : null}
						<BottomBarActions>
							<PrimaryButton
								onClick={() => onEditCampaign()}
								isRotateIcon={isLoading || isSaving}
								icon={isLoading || isSaving ? 'loading-01' : 'send-03'}>
								Save
							</PrimaryButton>
						</BottomBarActions>
					</BottomBar>
				</Disable>
			</Page>
		</NavPage>
	);
});
