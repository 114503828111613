import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Status, Statuses } from '../../enums/status';
import { makeAutoObservable } from 'mobx';
import { BidderCampaignTargetingLinkModel, IBidderCampaignTargetingLink } from './bidder-campaign-targeting-link.model';

export interface IBidderCampaignTargeting {
	id?: id;
	status?: Status;
	country_code?: string;
	os?: string;
	browser?: string;
	excluded_browser?: string;
	device_type?: string;
	black_hour?: string;
	rh_offer_id?: string;
	rh_publisher_id?: string;
	estimated_rate?: number;
	q_estimated_rate?: number;
	campaign_targeting_links?: IBidderCampaignTargetingLink[];
}

export class BidderCampaignTargetingModel implements IBidderCampaignTargeting {
	private _id?: id;
	private _status?: Status;
	private _country_code?: string;
	private _os?: string;
	private _browser?: string;
	private _excluded_browser?: string;
	private _device_type?: string;
	private _black_hour?: string;
	private _rh_offer_id?: string;
	private _rh_publisher_id?: string;
	private _estimated_rate?: number;
	private _q_estimated_rate?: number;
	private _campaign_targeting_links?: BidderCampaignTargetingLinkModel[];

	constructor(data: Partial<IBidderCampaignTargeting>) {
		this._id = data.id;
		this._status = data.status;
		this._country_code = data.country_code;
		this._os = data.os;
		this._browser = data.browser;
		this._excluded_browser = data.excluded_browser;
		this._device_type = data.device_type;
		this._black_hour = data.black_hour;
		this._rh_offer_id = data.rh_offer_id;
		this._rh_publisher_id = data.rh_publisher_id;
		this._estimated_rate = data.estimated_rate;
		this._q_estimated_rate = data.q_estimated_rate;
		this._campaign_targeting_links = data.campaign_targeting_links?.map(link => new BidderCampaignTargetingLinkModel(link));

		makeAutoObservable(this);
	}

	get id(): id | undefined {
		return this._id ?? undefined;
	}

	set id(value: id | undefined) {
		this._id = value;
	}

	get status() {
		return this._status ?? Statuses.Active;
	}

	set status(value: Status) {
		this._status = value;
	}

	get country_code() {
		return this._country_code ?? '';
	}

	set country_code(value: string) {
		this._country_code = value;
	}

	get os() {
		return this._os ?? '';
	}

	set os(value: string) {
		this._os = value;
	}

	get browser() {
		return this._browser ?? '';
	}

	set browser(value: string) {
		this._browser = value;
	}

	get excluded_browser() {
		return this._excluded_browser ?? '';
	}

	set excluded_browser(value: string) {
		this._excluded_browser = value;
	}

	get device_type() {
		return this._device_type ?? '';
	}

	set device_type(value: string) {
		this._device_type = value;
	}

	get black_hour() {
		return this._black_hour ?? '';
	}

	set black_hour(value: string) {
		this._black_hour = value;
	}

	get rh_offer_id() {
		return this._rh_offer_id ?? '';
	}

	set rh_offer_id(value: string) {
		this._rh_offer_id = value;
	}

	get rh_publisher_id() {
		return this._rh_publisher_id ?? '';
	}

	set rh_publisher_id(value: string) {
		this._rh_publisher_id = value;
	}

	get estimated_rate() {
		return this._estimated_rate ?? 0;
	}

	set estimated_rate(value: number) {
		this._estimated_rate = value;
	}

	get q_estimated_rate() {
		return this._q_estimated_rate ?? 0;
	}

	set q_estimated_rate(value: number) {
		this._q_estimated_rate = value;
	}

	get campaign_targeting_links(): BidderCampaignTargetingLinkModel[] {
		return this._campaign_targeting_links ?? [];
	}

	set campaign_targeting_links(value: IBidderCampaignTargetingLink[]) {
		this._campaign_targeting_links = value.map(link => new BidderCampaignTargetingLinkModel(link));
	}

	addNewLink() {
		this._campaign_targeting_links = [
			...(this._campaign_targeting_links || []),
			new BidderCampaignTargetingLinkModel({
				id: 0,
				rh_oid: this.rh_offer_id,
				rh_pid: this.rh_publisher_id,
			}),
		];
	}

	toObject() {
		return {
			id: this.id,
			status: this.status,
			country_code: this.country_code,
			os: this.os,
			browser: this.browser,
			excluded_browser: this.excluded_browser,
			device_type: this.device_type,
			black_hour: this.black_hour,
			rh_offer_id: this.rh_offer_id,
			rh_publisher_id: this.rh_publisher_id,
			estimated_rate: this.estimated_rate,
			q_estimated_rate: this.q_estimated_rate,
			campaign_targeting_links: this._campaign_targeting_links?.map(link => link.toObject()),
		};
	}
}
